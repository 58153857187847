<template>
  <div class="venue-payment-methods grid-2">
    <div>
      <p class="venue-payment-methods__label">Payment methods</p>
      <div class="venue-payment-methods__checkboxes">
        <Checkbox :value="true" isDisabled>Stripe</Checkbox>
        <div
          v-for="(method, index) in innerValue"
          :key="index"
          class="venue-payment-methods__method"
        >
          <Checkbox
            :value="method.isActive"
            :is-disabled="isDisabled"
            @input="handleChange(method)"
          >
            {{ method.name }}
          </Checkbox>
          <Icon
            name="trash"
            color="secondary-400"
            isClickable
            @click="deletePaymentMethod(index)"
          />
        </div>
      </div>
    </div>
    <transition-group
      class="venue-payment-methods__custom"
      tag="div"
      name="slide"
    >
      <Input
        key="input"
        v-model="customPaymentsMethod"
        placeholder="Custom method name"
        label="Add custom method"
        :is-disabled="isDisabled"
        class="venue-payment-methods__custom__input"
        :class="{ 'm-shrinked': !isDisabled && isCustomMethodValid }"
      />
      <IconButton
        key="button"
        v-if="!isDisabled && isCustomMethodValid"
        class="mt-24"
        icon="plus"
        @click="addPaymentMethod"
      >
        Add method
      </IconButton>
    </transition-group>
  </div>
</template>

<script>
export default {
  name: "VenuePaymentMethods",
  props: {
    value: {
      type: Array,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { customPaymentsMethod: "" };
  },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      },
    },
    isCustomMethodValid() {
      const names = [
        "stripe",
        ...this.innerValue.map((item) => item.name.toLowerCase()),
      ];
      return (
        this.customPaymentsMethod &&
        !names.includes(this.customPaymentsMethod.toLowerCase())
      );
    },
  },
  methods: {
    deletePaymentMethod(index) {
      const copy = [...this.innerValue];
      copy.splice(index, 1);
      this.innerValue = copy;
    },

    addPaymentMethod() {
      if (this.isCustomMethodValid) {
        const newPaymentMethod = {
          name: this.customPaymentsMethod,
          isActive: true,
        };
        this.innerValue = [...this.innerValue, newPaymentMethod];
        this.customPaymentsMethod = "";
      }
    },
    handleChange(method) {
      const copy = [...this.innerValue];
      const index = copy.findIndex((item) => {
        return item.name === method.name;
      });
      if (index !== -1) {
        const changedMethod = copy[index];
        copy.splice(index, 1, {
          name: changedMethod.name,
          isActive: !changedMethod.isActive,
        });
        this.innerValue = copy;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$animation-duration: 0.2s;

// .checkbox__label {
//   color: var(--color-secondary-500);
//   display: flex;
//   align-items: center;
// }

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateX(150px);
}
.slide-enter-active,
.slide-leave-active {
  position: absolute;
  right: 0;
  transition: $animation-duration;
}

.venue-payment-methods {
  &__label {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: $secondary-500;
    margin-bottom: 16px;
  }

  &__checkboxes {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-wrap: wrap;
  }

  &__method {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;
  }

  &__custom {
    display: flex;
    align-items: center;
    gap: 12px;

    &__input {
      flex-basis: 100%;
      transition: $animation-duration;

      &.m-shrinked {
        flex-basis: 70%;
      }
    }
  }
}
</style>
