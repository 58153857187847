<template>
  <Form class="venue-form" v-slot="{ invalid, changed }">
    <div>
      <div v-if="isEditing" class="venue-form__status" :class="venueStatus">
        <Icon :name="venueStatusIcon" color="secondary-500" />
        {{ venueStatusName }}
      </div>
      <div class="mb-24" :class="!isOnboard ? 'grid-2' : 'venue-form__form'">
        <FormItem rules="required|min:4|kebab-case">
          <Input
            v-model="form.id"
            placeholder="Unique ID"
            :label="isOnboard ? 'Unique ID' : ''"
            :isDisabled="isDisabled || isEditing"
          />
        </FormItem>
        <FormItem rules="required">
          <Input
            v-model="form.name"
            placeholder="Name"
            :label="isOnboard ? 'Name' : ''"
            :isDisabled="isDisabled"
          />
        </FormItem>
        <FormItem rules="required">
          <Input
            v-model="form.companyName"
            placeholder="Company name"
            :label="isOnboard ? 'Company name' : ''"
            :isDisabled="isDisabled || isEditing"
          />
        </FormItem>
        <FormItem rules="required|url">
          <Input
            v-model="form.companySite"
            placeholder="Company site"
            :label="isOnboard ? 'Company site' : ''"
            :isDisabled="isDisabled || isEditing"
          />
        </FormItem>
        <FormItem rules="required">
          <Select
            v-model="form.country"
            :options="countryOptions"
            placeholder="Country"
            :label="isOnboard ? 'Country' : ''"
            :isDisabled="isDisabled || isEditing"
          />
        </FormItem>
        <FormItem
          v-if="!form.country || timezoneOptions.length"
          rules="required"
        >
          <Select
            v-model="form.timezone"
            :options="timezoneOptions"
            :label="isOnboard ? 'Timezone' : ''"
            placeholder="Timezone"
            :isDisabled="isDisabled || !form.country"
          />
        </FormItem>
        <FormItem rules="required">
          <Input
            v-model="form.address"
            placeholder="Address"
            :label="isOnboard ? 'Address' : ''"
            :is-disabled="isDisabled"
          />
        </FormItem>
        <FormItem rules="required|email">
          <Input
            v-model="form.email"
            placeholder="Email"
            :label="isOnboard ? 'Email' : ''"
            :is-disabled="isDisabled"
          />
        </FormItem>
        <FormItem rules="required|phone:17">
          <Input
            v-model="form.phone"
            placeholder="Phone"
            mask="+1 (###) ###-####"
            :label="isOnboard ? 'Phone' : ''"
            :is-disabled="isDisabled"
          />
        </FormItem>
        <FormItem v-if="isSuperadminForm" rules="required|email">
          <Input
            v-model="form.ownerEmail"
            placeholder="Owner's email"
            :label="isOnboard ? 'Owner\'s email' : ''"
            :is-disabled="isDisabled"
          />
        </FormItem>
        <template v-if="isEditing">
          <FormItem class="mb-24">
            <Select
              v-model="form.firstDayOfWeek"
              :options="weekdaysOptions"
              :label="isOnboard ? 'Start of the week' : ''"
              placeholder="Start of the Week"
              :isDisabled="isDisabled"
            />
          </FormItem>
          <FormItem>
            <Select
              v-model="form.timeFormat"
              :options="timeFormatOptions"
              :isDisabled="isDisabled"
              placeholder="Time Format"
            />
          </FormItem>
          <FormItem>
            <Select
              v-model="form.dateFormat"
              :options="dateFormatOptions"
              :isDisabled="isDisabled"
              placeholder="Date Format"
            />
          </FormItem>
          <FormItem class="venue-form__money-format">
            <Input
              v-model="form.moneyFormat"
              :isDisabled="isDisabled"
              placeholder="Money format"
            />
            <Icon
              name="info"
              color="secondary-400"
              v-tooltip="{
                content: moneyFormatHint,
                trigger: 'hover click',
                classes: 'venue-form__tooltip',
              }"
            />
          </FormItem>
          <FormItem>
            <Input
              v-model="form.phoneFormat"
              :isDisabled="isDisabled"
              placeholder="Phone format"
            />
          </FormItem>
          <FormItem>
            <Select
              v-model="form.widget"
              :options="widgetThemeOptions"
              :isDisabled="isDisabled"
              placeholder="Widget Theme"
            />
          </FormItem>
          <FormItem rules="kebab-case-with-upper">
            <Input
              v-model="form.registrationCodePrefix"
              placeholder="Register code prefix"
              :label="isOnboard ? 'Register code prefix' : ''"
              :is-disabled="isDisabled"
            />
          </FormItem>
          <FormItem>
            <Input
              v-model="form.coachNote"
              label="Coach note"
              placeholder="Enter note"
              is-textarea
            />
          </FormItem>
          <FormItem :rules="{ numeric: true, minValue: 1 }">
            <Input
              v-model="form.coachNoteCutoffTime"
              placeholder="48"
              label="Coach note cutoff time (in hours)"
            />
          </FormItem>
        </template>
        <FormItem :rules="{ numeric: true, minValue: 1 }">
          <Input
            v-model="form.bookingLifeTime"
            placeholder="Enter time in minutes"
            label="Temporary booking lifetime (in minutes)"
          />
        </FormItem>
      </div>

      <template v-if="isEditing">
        <div class="mb-24">
          <FormItem class="mb-24 grid-2">
            <Input
              isDisabled
              :value="genericWaiverLink"
              label="Generic waiver link"
            />
          </FormItem>
          <Button
            :is-block="['xs', 'sm'].includes($mq)"
            isOutlined
            @click="copyGenericWaiverLink"
          >
            <Icon name="clipboard" color="primary" />
            Copy generic waiver link
          </Button>
        </div>
      </template>
    </div>
    <div
      v-if="
        isEditing &&
        !isVenueLive &&
        (isVenuePending || isVenueInactive || isSuperadmin)
      "
      class="venue-form__buttons"
    >
      <template v-if="isVenuePending && isSuperadmin">
        <Button
          :is-block="['xs', 'sm'].includes($mq)"
          :is-loading="isApproving"
          :is-disabled="isRejecting"
          @click="$emit('approve', true)"
        >
          Approve
        </Button>
        <Button
          :is-block="['xs', 'sm'].includes($mq)"
          variant="danger"
          isOutlined
          :is-loading="isRejecting"
          :is-disabled="isApproving"
          @click="$emit('approve', false)"
        >
          Reject
        </Button>
      </template>
      <Button
        v-else-if="(!isDisabled || isSuperadmin) && isVenueInactive"
        :is-block="['xs', 'sm'].includes($mq)"
        :is-loading="isApproving"
        @click="$emit('request-for-approval')"
      >
        Request for approval
      </Button>
    </div>

    <template v-if="isEditing">
      <div class="divider mt-64 mb-32" />
      <FormItem>
        <VenuePaymentMethods
          v-model="form.paymentMethods"
          :isDisabled="isDisabled"
        />
      </FormItem>
    </template>

    <template v-if="isEditing">
      <div class="divider mt-64 mb-32" />
      <h2 class="mb-24">Appearance</h2>
      <div class="grid-2">
        <FormItem>
          <ImageUploader
            v-model="form.logo"
            label="Logo"
            scope="venues"
            :max-size="200"
            :is-disabled="isDisabled"
          />
        </FormItem>
      </div>
    </template>

    <template v-if="isEditing">
      <div class="divider mt-64 mb-32" />
      <h2 class="mb-24">Social Networks</h2>
      <div class="grid-2">
        <FormItem rules="url">
          <Input
            v-model="form.youtubeLink"
            label="Youtube"
            placeholder="Enter Youtube Link"
          />
        </FormItem>
        <FormItem rules="url">
          <Input
            v-model="form.instagramLink"
            label="Instagram"
            placeholder="Enter Instagram Link"
          />
        </FormItem>
        <FormItem rules="url">
          <Input v-model="form.xLink" label="X" placeholder="Enter X Link" />
        </FormItem>
      </div>
    </template>

    <div
      v-if="!isDisabled"
      class="venue-form__buttons"
      :class="{
        'm-border':
          isEditing &&
          !isVenueLive &&
          ((isVenuePending && isSuperadmin) || isVenueInactive),
      }"
    ></div>

    <div class="venue-form__buttons__button">
      <Button
        :is-block="isOnboard || ['xs', 'sm'].includes($mq)"
        :disabled="invalid || !changed"
        :isLoading="isSubmitting"
        class="mt-64"
        @click="$emit('submit', form)"
      >
        {{ submitButtonText }}
      </Button>
    </div>
  </Form>
</template>

<script>
import VenuePaymentMethods from "./VenuePaymentMethods";
import { getTimezonesForCountry } from "countries-and-timezones";
import {
  getSortedCountries,
  generateWidgetWaiverGenericLink,
} from "@/helpers/utils";
import alert from "@/plugins/alert";
import { onboardVenuePathName } from "@/helpers/onboard";
import { mapState } from "vuex";
import { VenueStatusEnum, WidgetThemeEnum } from "@/helpers/enums";
import { VENUE_STATUS_NAME_MAP } from "@/helpers/const";
import ImageUploader from "@/components/common/ImageUploader.vue";

export default {
  name: "VenueForm",
  components: {
    ImageUploader,
    VenuePaymentMethods,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    isApproving: {
      type: Boolean,
      default: false,
    },
    isRejecting: {
      type: Boolean,
      default: false,
    },
    submitButtonText: {
      type: String,
      default: "Save",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        ...this.value,
      },
      weekdaysOptions: [
        { name: "Sunday", value: 0 },
        { name: "Monday", value: 1 },
        { name: "Tuesday", value: 2 },
        { name: "Wednesday", value: 3 },
        { name: "Thursday", value: 4 },
        { name: "Friday", value: 5 },
        { name: "Saturday", value: 6 },
      ],
      timeFormatOptions: [
        { name: "12h", value: 12 },
        { name: "24h", value: 24 },
      ],
      dateFormatOptions: [
        { name: "mm/dd/yyyy", value: "MM/DD/YYYY" },
        { name: "dd/mm/yyyy", value: "DD/MM/YYYY" },
      ],
      widgetThemeOptions: [
        { name: "YoReferee", value: WidgetThemeEnum.YOREFEREE },
        { name: "YoReferee 2.0", value: WidgetThemeEnum.YOREFEREE2 },
        { name: "BadAxe", value: WidgetThemeEnum.BADAXE },
      ],
      moneyFormatHint: `
      Variables that can be used with examples:
      {{amount}} - 1,291.74
      {{amount_rounded}} - 1,292
      `,
    };
  },
  watch: {
    "form.country"() {
      this.form.timezone = "";
    },
  },
  computed: {
    ...mapState({
      venueStatus: (state) => state.venues.selectedVenue.status,
      isSuperadmin: (state) => state.users.isSuperadmin,
      selectedProduct: (state) => state.products.selectedProduct,
    }),
    venueStatusName() {
      return VENUE_STATUS_NAME_MAP[this.venueStatus];
    },
    venueStatusIcon() {
      return {
        [VenueStatusEnum.INACTIVE]: "cross-circle",
        [VenueStatusEnum.PENDING]: "history",
        [VenueStatusEnum.LIVE]: "live",
      }[this.venueStatus];
    },
    isVenueInactive() {
      return this.venueStatus === VenueStatusEnum.INACTIVE;
    },
    isVenuePending() {
      return this.venueStatus === VenueStatusEnum.PENDING;
    },
    isVenueLive() {
      return this.venueStatus === VenueStatusEnum.LIVE;
    },
    isOnboard() {
      return this.$route.name === onboardVenuePathName;
    },
    isEditing() {
      return this.$route.name === "EditVenue";
    },
    isSuperadminForm() {
      return this.$route.name === "SuperadminCreate";
    },
    countryOptions() {
      return getSortedCountries().map((country) => ({
        value: country.id,
        name: country.name,
      }));
    },
    timezoneOptions() {
      return (
        getTimezonesForCountry(this.form.country)?.map((zone) => ({
          value: zone.name,
          name: `${zone.name} (${zone.utcOffsetStr})`,
        })) || []
      );
    },

    genericWaiverLink() {
      return generateWidgetWaiverGenericLink(this.value);
    },
  },
  methods: {
    async copyGenericWaiverLink() {
      await navigator.clipboard.writeText(this.genericWaiverLink);
      alert.open({ message: "Generic waiver link copied" });
    },
  },
};
</script>

<style lang="scss">
.venue-form__tooltip {
  white-space: pre-line;
}
</style>

<style lang="scss" scoped>
.venue-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__status {
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: $secondary-200;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: $secondary-500;
    padding: 12px 16px;
    border-radius: 8px;
    margin-bottom: 24px;
    transition: 0.3s;

    &.inactive {
      background-color: $secondary-200;
    }

    &.pending {
      background-color: #f8d2671a;
    }

    &.live {
      background-color: #3fab7714;
    }
  }

  &__money-format {
    display: flex;
    gap: 12px;
    align-items: center;

    :first-child {
      flex: 1;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__copyLinkBlock {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 40px;
    margin-top: 24px;

    &.m-border {
      border-top: 1px solid $secondary-300;
    }

    @media (min-width: $media-laptop) {
      display: flex;
      flex-direction: row;
      gap: 24px 20px;
      padding-top: 64px;
      margin-top: 40px;
    }
  }
}
</style>
